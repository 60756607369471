import React, { Component } from 'react'
import { BrowserRouter, Route, Switch, } from 'react-router-dom'
import './scss/style.scss'
import  './assets/css/common.css';
import Login from './login/login'
import SignUp from './signup/Signup'


const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
//const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
const GridExample = React.lazy(()=> import('./location/agTest'))
const AddDeviceMobile = React.lazy(()=>import ('./addDevice/AddDeviceMobile'));
const DashboardMobile = React.lazy(()=>import('./dashboard/DashboardMobile'));
const LocationMobile = React.lazy(()=>import('./location/LocationMobile'));
const DonutChartMobile = React.lazy(()=>import('./visualReport/components/donutChartMobile'));
const VisualReportMobile = React.lazy(()=>import('./visualReport/VisualReportMobile'));
const ResetPassword =  React.lazy(()=>import('./resetpassword/ResetPassword'))
const DashboardMobileLanding = React.lazy(()=>import('./dashboard/DashboardMobileLanding'))

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            
            <Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
            <Route exact path="/500" name="Page 500" render={(props) => <Page500 {...props} />} />
            <Route exact path="/login" name="Login Page" render={(props) => <Login {...props} />} />
            <Route exact path="/dashboard-landing/:userSession/:org_id" name="Dashboard" render={(props) => <DashboardMobileLanding {...props} />} />
            <Route exact path="/add-device/:userSession/:org_id" name="Add Device" render={(props) => <AddDeviceMobile {...props} />} />
            <Route exact path="/dashboard/:userSession/:org_id/:location_id/:index/:view/:heightChart" name="Dashboard" render={(props) => <DashboardMobile {...props} />} />
            <Route exact path="/location/:userSession/:org_id/:location_id/:index/:view/:heightChart" name="location" render={(props) => <LocationMobile {...props} />} />
            <Route exact path="/visual-report-index/:userSession/:org_id/:index/:view" name="VIsual Report" render={(props) => <DonutChartMobile {...props} />} />
            <Route exact path="/visual-report-mobile/:userSession/:org_id/:index/:view/:locations/:heightChart" name="Visual Report" render={(props) => <VisualReportMobile {...props} />} />
            <Route exact path="/signup" name="Signup Page" render={(props) => <SignUp {...props} />} />
            <Route exact path="/reset-password" name="Signup Page" render={(props) => <ResetPassword {...props} />} />
            <Route
              exact
              path="/register"
              name="Register Page"
              render={(props) => <Register {...props} />}
            />
            <Route path="/" name="Home" render={(props) => <DefaultLayout {...props} />} />
            <Route path="/grid" name="AG Grid" render={(props) => <GridExample {...props} /> } />
           </Switch>
        </React.Suspense>
      </BrowserRouter>
    )
  }
}

export default App
